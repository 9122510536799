import React, { useContext, useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  Navigate,
  NavLink,
} from "react-router-dom";
import admission from "../../assets/images/icons/admission.png";
import { LoginContext } from "../contexts/LoginContext";
import "font-awesome/css/font-awesome.min.css";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import prof from "../../assets/images/icons/doctor1.png";
import request from "../../assets/images/icons/request1.png";
import patient from "../../assets/images/icons/patient.png";
import visitor from "../../assets/images/icons/visitor.png";
import dialysis from "../../assets/images/icons/dialysis.png";
import PhysioSvg from "../PhysioTherappy/Physiosvg";
import PalliativeSvg from "../Palliative/palliativesvg";
function HODashboard() {
  const [features, setFeatures] = useState(JSON.parse(sessionStorage.getItem("features")))
  const [web_users, setweb_users] = useState(JSON.parse(sessionStorage.getItem("web_users")))
  const [app_users, setapp_users] = useState(JSON.parse(sessionStorage.getItem("app_users")))
  const [loginDatas] = useContext(LoginContext);
  const projType = sessionStorage.getItem("project_type")
  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  return (
    <div>
      {/* <Navbar /> */}
      <div className="home">
        <h2 className="title-of-page"> {login_datas.name} </h2>
        <h5 className="subtitle-of-page" style={{ marginBottom: "0%" }}>{login_datas.location}</h5>

        <div className="home-container" style={{ marginTop: "1%" }}>


          {features && features.includes("ip_form_admission") && <div className="home-tiles"
            onClick={() => navigate("/admissionchairman")}
          >
            <img src={admission} className="home-icon" width="65px" />
            <h5 className="each-title">Admissions</h5>

          </div>}
          <div className="home-tiles"
            onClick={() => navigate("/patientlist")}
          >
            <img src={patient} className="home-icon" width="65px" />
            <h5 className="each-title"> Patient Records
            </h5>

          </div>
          {features && features.includes("visitor_registration") && <div className="home-tiles"
            onClick={() => navigate("/visitor_requests")}
          >
            <img src={visitor} className="home-icon" width="65px" />
            <h5 className="each-title"> Visitors
            </h5>

          </div>}
          <div className="home-tiles"
            onClick={() => navigate("/dialysis")}
          >
            <img src={dialysis} className="home-icon" width="65px" />
            <h5 className="each-title">Dialysis</h5>
          </div>
          <div className="home-tiles" onClick={() => navigate("/Phys")}          >
            <div className="home-icon" style={{ display: 'flex', alignItems: 'center' }} width="65px">
              <PhysioSvg />
            </div>
            <h5 className="each-title">Physiotherapy unit</h5>
          </div>
          <div className="home-tiles" onClick={() => navigate("/palliative")}          >
            <div className="home-icon" style={{ display: 'flex', alignItems: 'center' }} width="65px">
              <PalliativeSvg />
            </div>
            <h5 className="each-title">Palliative Care</h5>
          </div>
        </div>
      </div>

    </div>
  );
}

export default HODashboard;
