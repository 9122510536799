import React, { useContext, useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  Navigate,
  NavLink,
} from "react-router-dom";
import admission from "../../assets/images/icons/admission.png";
import { LoginContext } from "../contexts/LoginContext";
import "font-awesome/css/font-awesome.min.css";
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import "../Hospital/hospital.css";
//import Navbar from "../common/Navbar"
//mport Footer from "../common/Footer";
import dialysis from "../../assets/images/icons/dialysis.png";
import PhysioSvg from "../PhysioTherappy/Physiosvg";
import prof from "../../assets/images/icons/doctor1.png";
import request from "../../assets/images/icons/request1.png";
import bdo from "../../assets/images/icons/bdo.png";
import carer from "../../assets/images/icons/carer.webp";
import sda from "../../assets/images/icons/sda1.png";
import pharmacy from "../../assets/images/icons/pharmacy.png";
import patient from "../../assets/images/icons/patient.png";
import lab from "../../assets/images/icons/lab.png";
import nurse from "../../assets/images/icons/nurse1.png";
import allied from "../../assets/images/icons/allied.png";
import monitor from "../../assets/images/icons/monitor.png";
import fac from "../../assets/images/icons/facility.png";
import visitor from "../../assets/images/icons/visitor.png";
import manager from "../../assets/images/icons/manager.png";
import reports from "../../assets/images/icons/reports.png";
import PRO from "../../assets/images/icons/PRO.png";
import PalliativeSvg from "../Palliative/palliativesvg";
import usermngmnt from "../../assets/images/usermanagement.jpg";
import { UserManagementContext } from "../contexts/UserManagementContext";
import { UserManagementRenderContext } from "../contexts/UserManagementRenderContext";
function ManagerDashboard() {
  const [features, setFeatures] = useState(JSON.parse(sessionStorage.getItem("features")))
  const [web_users, setweb_users] = useState(JSON.parse(sessionStorage.getItem("web_users")))
  const [app_users, setapp_users] = useState(JSON.parse(sessionStorage.getItem("app_users")))
  const [selected, setSelected] = useContext(UserManagementContext)
  const [rendered, setRendered] = useContext(UserManagementRenderContext)
  const [loginDatas] = useContext(LoginContext);
  const projType = sessionStorage.getItem("project_type")
  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  useEffect(() => {
    setSelected('doctors')
    setRendered('users')
  },[])

  return (
    <div>

      <div className="home">
        <h2 className="title-of-page"> {login_datas.name} </h2>

        <div className="home-container" style={{ marginTop: "1%" }}>


          {features && features.includes("ip_form_admission") && <div className="home-tiles"
            onClick={() => navigate("/admissionadmin")}
          >
            <img src={admission} className="home-icon" width="65px" />
            <h5 className="each-title">Admissions</h5>

          </div>}
          {features && features.includes("monitor") &&
            <div className="home-tiles"
              onClick={() => navigate("/monitor")}
            >
              <img src={monitor} className="home-icon" width="65px" />
              <h5 className="each-title">Monitor</h5>

            </div>}

          <div className="home-tiles"
            onClick={() => navigate("/patientlist")}
          >
            <img src={patient} className="home-icon" width="65px" />
            <h5 className="each-title"> Patient Records
            </h5>

          </div>
          {features && features.includes("visitor_registration") && <div className="home-tiles"
            onClick={() => navigate("/visitor_requests")}
          >
            <img src={visitor} className="home-icon" width="65px" />
            <h5 className="each-title"> Visitors
            </h5>

          </div>}
          {(features && features.includes("ip_form_admission") || features && features.includes("ip_admission")) && <div className="home-tiles" onClick={() => navigate("/hospitalmanagement")}>
            <img src={fac} className="home-icon" width="65px" />
            <h5 className="each-title">Facility Management</h5>
          </div>}
          {features && features.includes("death_management") && <div className="home-tiles"
            onClick={() => navigate("/deathreport")}
          >
            <img src={reports} className="home-icon" width="65px" />
            <h5 className="each-title">Death Reports</h5>

          </div>}
          <div className="home-tiles" onClick={() => navigate("/usermanagement")}>
            <img src={usermngmnt} className="home-icon" width="60px" />
            <h5 className="each-title"> User Management</h5>
          </div>
          <div className="home-tiles"
            onClick={() => navigate("/dialysis")}
          >
            <img src={dialysis} className="home-icon" width="65px" />
            <h5 className="each-title">Dialysis</h5>
          </div>
          <div className="home-tiles" onClick={() => navigate("/Phys")}          >
            <div className="home-icon" style={{ display: 'flex', alignItems: 'center' }} width="65px">
              <PhysioSvg />
            </div>
            <h5 className="each-title">Physiotherapy unit</h5>
          </div>
          <div className="home-tiles" onClick={() => navigate("/palliative")}          >
            <div className="home-icon" style={{ display: 'flex', alignItems: 'center' }} width="65px">
              <PalliativeSvg />
            </div>
            <h5 className="each-title">Palliative Care</h5>
          </div>
          {/* </> :null} */}
        </div>


      </div>

    </div>
  );
}

export default ManagerDashboard;
