import React, { useContext, useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  Navigate,
  NavLink,
} from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import "font-awesome/css/font-awesome.min.css";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import "./hospital.css";
import request from "../../assets/images/icons/request1.png";
import patient from "../../assets/images/icons/patient.png";
import fac from "../../assets/images/icons/facility.png";
import manager from "../../assets/images/icons/manager.png";
import usermngmnt from "../../assets/images/usermanagement.jpg";
import { UserManagementContext } from "../contexts/UserManagementContext";
import { UserManagementRenderContext } from "../contexts/UserManagementRenderContext";

function HomePage() {
  const [web_users, setweb_users] = useState(JSON.parse(sessionStorage.getItem("web_users")))
  const [app_users, setapp_users] = useState(JSON.parse(sessionStorage.getItem("app_users")))
  const [selected, setSelected] = useContext(UserManagementContext)
  const [rendered, setRendered] = useContext(UserManagementRenderContext)
  const [loginDatas] = useContext(LoginContext);
  const projType = sessionStorage.getItem("project_type")
  let navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));
  }, [loginDatas !== null]);

  useEffect(()=>{
    setSelected('manager')
    setRendered('user')
  },[])

  return (
    <div>
      <div className="home">
        <h2 className="title-of-page"> {login_datas.name} </h2>
        <h5 className="subtitle-of-page" style={{ marginBottom: "0%" }}>{login_datas.location}</h5>
        <div className="home-container" style={{ marginTop: "1%" }}>
          <div className="home-tiles" onClick={() => navigate("/patientlist")}          >
            <img src={patient} className="home-icon" width="65px" />
            <h5 className="each-title"> Patient Records</h5>
          </div>
          <div className="home-tiles" onClick={() => navigate("/usermanagement")}>
            <img src={usermngmnt} className="home-icon" width="60px" />
            <h5 className="each-title"> User Management</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
